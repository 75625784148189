import React from "react";
import { HeadingTertiary, HeadingQuatinary } from "../Headings/Heading";
import { TextInput } from "../FormComponents/TextInput";
import { Dropdown } from "../FormComponents/Dropdown";
import { CustomNameIcon } from "../FormComponents/CustomIcons";
import { TextArea } from "../FormComponents/TextArea";

const BankingInformation = () => {
  const binaryOptions = ["Yes", "No"];
  const optionsForZakaahCollection = ["Cheque", "Bank Transfer"];
  const accountTypes = ["Savings", "Chequings"];
  return (
    <>
      <HeadingQuatinary title="Banking Information - To be filled out only by those applying for regular assistance" />
      <div
        className="my-5"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr", columnGap: "5rem", rowGap: "4rem" }}
      >
        <Dropdown label="Do you have a bank account?" options={binaryOptions} />
        <Dropdown
          label="If approved which option woud you prefer to collect the Zakaah?"
          options={optionsForZakaahCollection}
        />
        <TextInput
          label="Please provide the name the cheque is to be made to"
          error={false}
          helperText="Invalid input"
          placeholder="Name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="35rem"
        />
        <div />

        {/* <HeadingQuatinary
          title="Plese fill in the following bank transfer information"
          optionalProps={{ style: { justifySelf: "flex-start", gridColumn: "span 2" } }}
        /> */}
        <h1 className="text-start font-secondary fs-4 fw-bold text-quatinary-2 my-2" style={{ gridColumn: "span 2" }}>
          Please fill in the following bank transfer information
        </h1>

        <TextInput
          label="Transit Number"
          error={false}
          helperText="Invalid input"
          placeholder="Number"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Account Number"
          error={false}
          helperText="Invalid input"
          placeholder="Number"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Bank"
          error={false}
          helperText="Invalid input"
          placeholder="Name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Bank Branch"
          error={false}
          helperText="Invalid input"
          placeholder="Branch"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />

        <Dropdown label="Account Type" options={accountTypes} />
        <div />
        <TextArea label="Explain your current situation and your need for support" />
      </div>
    </>
  );
};

export default BankingInformation;
