import React from "react";
import classes from "./SalaahCard.module.css";

const JummahCard = () => {
  return (
    <div className={`${classes.salaahCardContainer} font-secondary fs-5 fw-semi-bold`}>
      <div>Jummah</div>
      <div>12:25 pm</div>
      <div className={classes.iqamah}>
        2nd Jummah <br /> 2:00 pm
      </div>
    </div>
  );
};

export default JummahCard;
