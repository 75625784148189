import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";

// Custom styled TextField to ensure label and placeholder are visible simultaneously
const StyledTextField = styled(TextField)(({ theme }) => ({
  position: "relative",
  width: "100%",
  "& .MuiInputLabel-root": {
    fontFamily: "Nunito, sans-serif",
    fontSize: "1.6rem",
    position: "absolute",
    top: "0.2rem", // Adjust this value to position the label
    left: "0.2rem",
    color: "#9389F4",
    backgroundColor: "#fff",
    transition: "all 0.2s ease",
    pointerEvents: "none",
    paddingRight: ".5rem",
    // Ensure label is above the input
    zIndex: 1,
  },
  "& .MuiOutlinedInput-root": {
    paddingTop: "1rem", // Adjust based on label position
    "& fieldset": {
      borderColor: "#B9DEDC",
    },
    "&:hover fieldset": {
      borderColor: "#B9DEDC",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#B9DEDC",
    },
  },
  "& .MuiInputBase-input": {
    fontFamily: "Nunito, sans-serif",
    fontSize: "1.4rem",
    padding: ".2rem 0.75rem",
    paddingTop: "1.5rem",
  },
  "& .MuiInputBase-input::placeholder": {
    fontFamily: "Nunito, sans-serif",
    fontSize: "1.4rem",
    color: "#467875",
  },
}));

export const TextArea = (props) => {
  const { label, value } = props;
  const [text, setText] = useState(value || "");
  const maxWords = 500;

  const handleChange = (event) => {
    const newText = event.target.value;
    const wordCount = newText.split(/\s+/).filter(Boolean).length;

    if (wordCount <= maxWords) {
      setText(newText);
    }
  };

  const wordCount = text.split(/\s+/).filter(Boolean).length;
  const remainingWords = maxWords - wordCount;

  return (
    <StyledTextField
      id="outlined-multiline-static"
      label={label}
      multiline
      rows={4}
      value={text}
      onChange={handleChange}
      placeholder="Type here..."
      helperText={`You can type ${remainingWords} more words.`}
      FormHelperTextProps={{
        style: { fontFamily: "Nunito, sans-serif", fontSize: "1.2rem", fontWeight: "400", color: "#467875" },
      }}
    />
  );
};
