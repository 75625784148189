import React from "react";

const AccordionItem = ({ title, content, identification, collapseId, active, onClick }) => {
  return (
    <div className="accordion-item mb-3 border-0">
      <h2 className="accordion-header" id={identification}>
        <button
          className="accordion-button fs-4 font-primary text-grey-100 bg-quatinary-three"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${collapseId}`}
          aria-expanded={active ? true : false}
          aria-controls={collapseId}
          onClick={onClick}
        >
          {title}
        </button>
      </h2>
      <div
        id={collapseId}
        className={`accordion-collapse collapse ${active}`}
        aria-labelledby={identification}
        data-bs-parent="#zakaahFaqAccordion"
      >
        <div className="accordion-body fs-5 font-primary">{content}</div>
      </div>
    </div>
  );
};

export default AccordionItem;
