import { useState, Fragment } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AcceptConditionsModal from "./AcceptConditionsModal";
import { IoPersonCircle } from "react-icons/io5";
import { FaMoneyCheck } from "react-icons/fa";
import { RiBankFill } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import PersonalForm from "./PersonalForm";
import Eligibility from "./Eligibility";
import BankingInformation from "./BankingInformation";
import FinancialInformation from "./FinancialInformation";
const steps = ["Personal", "Zakaah Eligibility", "Financial Information", "Banking Information"];
const icons = [IoPersonCircle, GiReceiveMoney, FaMoneyCheck, RiBankFill];

const Zakaah = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const isStepOptional = (step) => {
    return step === 100;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: "80%", margin: "2rem auto" }}>
      <AcceptConditionsModal open={open} handleClose={handleClose} />
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">Optional</Typography>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                sx={{
                  "& .MuiStepLabel-label": {
                    fontFamily: "Nunito",
                    fontSize: "1.8rem",
                    color: activeStep >= index ? "#6153f0 !important" : "#b9dedc",
                  },

                  "& .MuiStepLabel-iconContainer svg": {
                    width: "3.5rem",
                    height: "3.5rem",
                    color: activeStep >= index ? "#f7065d" : "#b9dedc",
                  },
                }}
                className="font-secondary fs-4"
                {...labelProps}
                StepIconComponent={icons[index]}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <Fragment>
        {/* CONTENT OF STEPPER */}
        {activeStep === 0 && <PersonalForm />}
        {activeStep === 1 && <Eligibility />}
        {activeStep === 2 && <FinancialInformation />}
        {activeStep === 3 && <BankingInformation />}

        <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
          <Button
            variant="contained"
            size="medium"
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{
              mr: 1,
              fontFamily: "Nunito",
              fontSize: "1.4rem",
              backgroundColor: "#6153f0",
              color: "#f4f4f4",
              "&:hover": {
                color: "#000",
              },
            }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          {/* {isStepOptional(activeStep) && (
            <Button
              color="inherit"
              onClick={handleSkip}
              sx={{
                mr: 1,
              }}
            >
              Skip
            </Button>
          )} */}

          <Button
            variant="contained"
            size="medium"
            onClick={activeStep === steps.length - 1 ? handleOpen : handleNext}
            sx={{
              fontFamily: "Nunito",
              fontSize: "1.4rem",
              backgroundColor: "#6153f0",
              color: "#f4f4f4",
            }}
          >
            {activeStep === steps.length - 1 ? "Finish" : "Next"}
          </Button>
        </Box>
      </Fragment>
    </Box>
  );
};

export default Zakaah;
