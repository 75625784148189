import React from "react";
import SlideShow from "../components/SlideShow/SlideShow";
import SalaahTimes from "../components/SalaahTimes/SalaahTimes";
import Events from "../components/Events/Events";
import Campaign from "../components/Campaigns/Campaign";
import Announcements from "../components/Announcements/Announcements";
import Ticker from "../components/Ticker/Ticker";

// API Key - AIzaSyDQM6PwBC_sh21mGLYFVqQFalmDxYE1TGA
const LandingPage = () => {
    return (
        <div>
            <Ticker />
            <SlideShow />
            <SalaahTimes />
            <Events />
            <Campaign />
            {/* <Announcements /> */}
        </div>
    );
};

export default LandingPage;
