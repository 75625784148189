import React from "react";
import { HeadingSecondary, HeadingTertiary } from "../Headings/Heading";
import { IoSettingsSharp } from "react-icons/io5";
import { FaMosque, FaGraduationCap } from "react-icons/fa";
import { ReactComponent as Charity } from "../../resources/icons/donation.svg";
import classes from "./Campaign.module.css";

const CampaignGrid = (props) => {
  return (
    <div
      className={`${props.background} col-10 col-sm-8 col-md-5 col-lg-4 rounded position-relative my-2 ${classes.card}`}
    >
      <div
        className="text-center rounded-pill mx-auto mb-2 position-absolute top-0 start-50 translate-middle"
        style={{ width: "6.1rem", backgroundColor: "#fff" }}
      >
        {props.title === "GENERAL" && <IoSettingsSharp fill={props.iconColor} size={50} className="my-2" />}
        {props.title === "BUILDING" && <FaMosque fill={props.iconColor} size={50} className="my-2" />}

        {props.title === "ZAKAAH" && <Charity fill={props.iconColor} className="my-2" height="4.2rem" width="4rem" />}

        {props.title === "EDUCATION" && <FaGraduationCap fill={props.iconColor} size={50} className="my-2" />}
      </div>
      <HeadingSecondary title={props.title} color="text-light" weight="fw-bolder" margin="mt-5" fontSize="fs-1" />
      <HeadingTertiary title={props.category} color="text-light" weight="fw-bold" />
      <p className="text-center font-secondary fs-4 fw-semi-bold text-grey-100">{props.account}</p>
      <p className="text-center font-secondary fs-4 fw-semi-bold text-grey-100">{props.description}</p>
    </div>
  );
};

export default CampaignGrid;
