import { createSlice } from "@reduxjs/toolkit";
import { auth, firestore } from "../../components/Auth/firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updateProfile,
} from "firebase/auth";
import { displayNotificationWithTimeout } from "./NotificationSlice";

const initialState = {
  user: null,
  accessToken: null,
  displayName: null,
  isAuthenticated: false,
  userPermission: null,
  loading: true,
};

export const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.displayName = action.payload.displayName;
      state.isAuthenticated = true;
      state.userPermission = action.payload.userPermission;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    clearUser: (state) => {
      state.accessToken = null;
      state.displayName = null;
      state.isAuthenticated = false;
      state.userPermission = null;
    },
  },
});

export const { setUser, setLoading, clearUser } = AuthSlice.actions;

export const registerUser = (fullName, email, password) => async (dispatch) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    /*Adding the user's display name */
    await updateProfile(userCredential.user, { displayName: fullName });

    // Save additional user info in Firestore
    const userDoc = {
      fullName,
      email,
      userType: "user",
      createdAt: new Date(),
    };

    await setDoc(doc(firestore, "users", userCredential.user.uid), userDoc);

    dispatch(
      setUser({
        accessToken: userCredential.user.accessToken,
        displayName: fullName,
        userPermission: userDoc.userType,
      })
    );
    dispatch(displayNotificationWithTimeout("You have successfully registered"));
    return { success: true, user: userCredential.user };
  } catch (error) {
    dispatch(displayNotificationWithTimeout(error.message));
    return { success: false, error: error.message };
  }
};

export const loginUser = (email, password) => async (dispatch) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const userDocRef = doc(firestore, "users", userCredential.user.uid);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      console.log(userData);
      dispatch(
        setUser({
          accessToken: userCredential.user.accessToken,
          displayName: userCredential.user.displayName,
          userPermission: userData.userType,
        })
      );
    }
    dispatch(displayNotificationWithTimeout("You have successfully signed in"));
    return { success: true, user: userCredential.user };
  } catch (error) {
    dispatch(displayNotificationWithTimeout(error.message));
    return { success: false, error: error.message };
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await signOut(auth);
    dispatch(clearUser());
  } catch (error) {
    dispatch(displayNotificationWithTimeout(error.message));
  }
};

export const observeAuthState = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(setUser(user));
    } else {
      dispatch(clearUser());
    }
    dispatch(setLoading(false));
  });
};

export default AuthSlice.reducer;
