import React from "react";

const Videos = (props) => {
  const { videoId, active, title, description, source, activeVideoParams, index } = props;

  const handleSideVideoChange = () => {
    activeVideoParams(index, videoId);
  };

  return (
    <div
      id={videoId}
      className={`khutbah__video ${videoId === active ? "bg-primary-three border" : ""}`}
      onClick={handleSideVideoChange}
    >
      <img className="khutbah__video--thumbnail" src={`${source}`}></img>
      <div className="khutbah__video--text text-start">
        <span className={`font-secondary fw-semi-bold fs-4  ${videoId === active ? "text-grey-100" : "text-grey-500"}`}>
          {title}
        </span>
        <br />
        <span className={`font-secondary fw-semi-bold fs-6 ${videoId === active ? "text-grey-100" : "text-grey-500"}`}>
          {description}
        </span>
      </div>
    </div>
  );
};

export default Videos;
