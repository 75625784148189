import React, { memo, useEffect } from "react";

export const ActiveVideo = ({ activeVideoId, activeVideoIdx }) => {
  return (
    <div className="col-xxl-8 col-xl-7 col-lg-12 col-md-12">
      <iframe
        className="khutbah_current mb-lg-4 mb-md-4"
        src={`https://www.youtube.com/embed?v=${activeVideoId}&list=${process.env.REACT_APP_YOUTUBE_PLAYLIST_ID}&index=${activeVideoIdx}`}
      ></iframe>
    </div>
  );
};

export const MemoizedActiveVideo = memo(ActiveVideo);
