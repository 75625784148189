import React from "react";
import { HeadingQuatinary, HeadingTertiary } from "../Headings/Heading";
import { Dropdown } from "../FormComponents/Dropdown";
import { TextInput } from "../FormComponents/TextInput";
import { FileUpload } from "../FormComponents/FileUpload";
import { TextArea } from "../FormComponents/TextArea";
import { CustomNameIcon } from "../FormComponents/CustomIcons";

const Eligibility = () => {
  const applicantType = [
    "New Applicant",
    "Previously applied unsuccessfully",
    "Previously helped more than 18 months ago",
  ];
  const zakaahNeed = [
    "Regular Assistance",
    "One-Off Housing Assistance",
    "One-Off Education Assistance",
    "One-Off Medical Assistance",
    "One-Off Automotive Assistance",
    "Other",
  ];

  const binaryOptions = ["Yes", "No"];
  return (
    <>
      <HeadingQuatinary title="Zakaah Eligibility" />
      <div className="my-5" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5rem" }}>
        <Dropdown label="Applicant Type" options={applicantType} />
        <Dropdown label="Zakaah Need" options={zakaahNeed} />
        <TextInput
          label="If 'Other' describe the Zakaah need"
          error={false}
          helperText="Invalid input"
          placeholder="Other need..."
          icon={<CustomNameIcon />}
          labelBorderSpacing="24rem"
        />
        <FileUpload label="Supported files types: PDF, JPG, PNG" innerText="Invoice" />
        {/* <Dropdown
          label="If you belong to a Jamaat other than Nur-E-Islam have you asked your Imam for Zakaah?"
          options={binaryOptions}
        /> */}
        <TextInput
          label={`If you belong to a Jamaat other than Nur-E-Islam have you asked your Imam for Zakaah?`}
          error={false}
          helperText="Invalid input"
          placeholder="Yes/No"
          icon={<CustomNameIcon />}
          labelBorderSpacing="55rem"
        />
        <TextArea label="If 'Yes' please indicate the response" />

        <TextInput
          label={`In the last six months, have you applied to any other Jamaat or Islamic Institution for Zakaah for this need?`}
          error={false}
          helperText="Invalid input"
          placeholder="Yes/No"
          icon={<CustomNameIcon />}
          labelBorderSpacing="55rem"
        />

        <TextArea
          label="If 'Yes' please state the name of the Jamaat/Islamic Institution, the date and the status of your
        application "
        />
      </div>
    </>
  );
};

export default Eligibility;
