import { createSlice } from "@reduxjs/toolkit";

export const NotificationSlice = createSlice({
  name: "notification ",
  initialState: {
    error: false,
    display: false,
    message: "Generic error message",
  },
  reducers: {
    displayNotification: (state, action) => {
      state.display = true;
      state.message = action.payload;
    },
    hideNotification: (state) => {
      state.display = false;
    },
  },
});

export const { displayNotification, hideNotification } = NotificationSlice.actions;
//export default NotificationSlice.reducer;

export const displayNotificationWithTimeout = (message) => (dispatch) => {
  dispatch(displayNotification(message));
  setTimeout(() => {
    dispatch(hideNotification());
  }, 3000);
};

export default NotificationSlice.reducer;
