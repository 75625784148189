import { useState } from "react";
import styles from "./Auth.module.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PasswordRecovery from "./PasswordRecovery";

const Auth = () => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["SIGN UP", "SIGN IN", "PASSWORD RECOVERY"];
  const changeActiveTab = (index) => {
    setActiveTab(index);
  };

  return (
    <div
      className="d-flex justify-content-between p-4 bg-grey-100"
      style={{
        margin: "2rem auto",
        width: "96rem",
        borderRadius: ".6rem",
        boxShadow: "0.3rem 0.3rem 2rem rgba(0, 0, 0, 0.4)",
      }}
    >
      <div>
        <ul
          className="d-flex px-0 justify-content-center bg-grey-200"
          style={{
            listStyle: "none",
            border: "2px solid #b9dedc",
            borderTopLeftRadius: ".6rem",
            borderTopRightRadius: ".6rem",
            cursor: "pointer",
          }}
        >
          {tabs.map((tab, index) => (
            <li
              key={index}
              className={`fs-3 font-primary fw-bold px-5 py-2 ${
                activeTab === index ? "text-primary-3 bg-grey-100" : "text-grey-100"
              }`}
              onClick={() => changeActiveTab(index)}
            >
              {tab}
            </li>
          ))}
        </ul>

        <h2 className={`${styles.auth_heading} font-primary fs-3 fw-bold text-primary-3 text-center my-5`}>
          {tabs[activeTab]}
        </h2>
        {activeTab === 0 && <SignUp />}
        {activeTab === 1 && <SignIn />}
        {activeTab === 2 && <PasswordRecovery />}
      </div>

      <img src="./images/AuthBackground.jpg" style={{ width: "45rem" }} />
    </div>
  );
};

export default Auth;
