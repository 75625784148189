import { TextInput } from "../FormComponents/TextInput";
import { Dropdown } from "../FormComponents/Dropdown";
import { FileUpload } from "../FormComponents/FileUpload";
import { Calendar } from "../FormComponents/Calendar";
import { HeadingTertiary, HeadingQuatinary } from "../Headings/Heading";

import {
  CustomNameIcon,
  CustomIdentificationIcon,
  CustomPhoneIcon,
  CustomStreetIcon,
} from "../FormComponents/CustomIcons";

const PersonalForm = () => {
  const cities = ["Port-of-Spain", "San Fernando"];
  const genders = ["Male", "Female"];
  const maritalStatuses = ["Single", "Married", "Divorced", "Seperated", "Widowed", "Common-Law"];
  const livingStatus = ["By self", "With spouse", "With children", "Other"];
  const employmentStatus = ["Employed", "Self-Employed", "Unemployed", "Other"];
  return (
    <>
      <HeadingQuatinary title="Personal" />
      <div className="my-5" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5rem" }}>
        <TextInput
          label="Name (as on ID)"
          error={false}
          helperText="Invalid input"
          placeholder="John Smith"
          icon={<CustomNameIcon />}
          labelBorderSpacing="12rem"
        />
        <Calendar label="Date of Birth" />
        <TextInput
          label="Street Address"
          error={false}
          helperText="Invalid input"
          placeholder="Address ..."
          icon={<CustomStreetIcon />}
          labelBorderSpacing="11rem"
        />

        <Dropdown label="City/Town" options={cities} />
        <FileUpload label="Supported files types: PDF, JPG, PNG" innerText="Imam's Letter" />
        <Dropdown label="Gender" options={genders} />

        <TextInput
          label="Contact Home"
          error={false}
          helperText="Invalid Input"
          icon={<CustomPhoneIcon />}
          placeholder="xxx-xxxx"
          labelBorderSpacing="10.5rem"
        />

        <TextInput
          label="Contact Work"
          error={false}
          helperText="Invalid Input"
          icon={<CustomPhoneIcon />}
          placeholder="xxx-xxxx"
          labelBorderSpacing="10.5rem"
        />

        <TextInput
          label="Contact Other"
          error={false}
          helperText="Invalid Input"
          icon={<CustomPhoneIcon />}
          placeholder="xxx-xxxx"
          labelBorderSpacing="10.5rem"
        />
        <div />
        <TextInput
          label="National ID"
          error={false}
          helperText="Invalid Input"
          icon={<CustomIdentificationIcon />}
          placeholder="ID number"
          labelBorderSpacing="9rem"
        />
        <FileUpload label="Supported files types: PDF, JPG, PNG" innerText="Upload National ID" />

        <TextInput
          label="Drivers Permit"
          error={false}
          helperText="Invalid Input"
          icon={<CustomIdentificationIcon />}
          placeholder="Permit Number"
          labelBorderSpacing="10.5rem"
        />
        <FileUpload label="Supported files types: PDF, JPG, PNG" innerText="Upload Drivers Permit" />

        <TextInput
          label="Passport"
          error={false}
          helperText="Invalid Input"
          icon={<CustomIdentificationIcon />}
          placeholder="Passport number"
          labelBorderSpacing="7rem"
        />
        <FileUpload label="Supported files types: PDF, JPG, PNG" innerText="Upload Passport" />

        <Dropdown label="Marital Status" options={maritalStatuses} />

        <div />

        <TextInput
          label="Name of spouse"
          error={false}
          helperText="Invalid input"
          placeholder="Spouse's name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11.5rem"
        />

        <TextInput
          label="Spouse ID/DP/PP#"
          error={false}
          helperText="Invalid input"
          placeholder="Spouse's identification"
          icon={<CustomNameIcon />}
          labelBorderSpacing="13rem"
        />

        <TextInput
          optionalProps={{
            style: { gridColumn: "span 2" },
          }}
          label="Which Jamaat do you belong to?"
          error={false}
          helperText="Invalid input"
          placeholder="Jamaat"
          icon={<CustomStreetIcon />}
          labelBorderSpacing="22.5rem"
        />

        <TextInput
          label="Imam's Name"
          error={false}
          helperText="Invalid input"
          placeholder="Name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="10.5rem"
        />

        <TextInput
          label="Imam's Cell"
          error={false}
          helperText="Invalid input"
          placeholder="Number"
          icon={<CustomNameIcon />}
          labelBorderSpacing="9rem"
        />

        <Dropdown label="Living Status" options={livingStatus} />
        <TextInput
          label="If 'Other' please explain"
          error={false}
          helperText="Invalid input"
          placeholder="Explain..."
          icon={<CustomNameIcon />}
          labelBorderSpacing="16.5rem"
        />
        <h1 className="text-start font-secondary fs-4 fw-bold text-quatinary-2 my-2">Household members</h1>
      </div>
      <div
        className="my-5"
        style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", columnGap: "2.5rem", rowGap: "5rem" }}
      >
        <TextInput
          label="Household member name"
          error={false}
          helperText="Invalid input"
          placeholder="Name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="18rem"
        />

        <TextInput
          label="Household member age"
          error={false}
          helperText="Invalid input"
          placeholder="Age"
          icon={<CustomNameIcon />}
          labelBorderSpacing="16.5rem"
        />

        <TextInput
          label="Relation to you"
          error={false}
          helperText="Invalid input"
          placeholder="Relation"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Household member name"
          error={false}
          helperText="Invalid input"
          placeholder="Name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="18rem"
        />

        <TextInput
          label="Household member age"
          error={false}
          helperText="Invalid input"
          placeholder="Age"
          icon={<CustomNameIcon />}
          labelBorderSpacing="16.5rem"
        />

        <TextInput
          label="Relation to you"
          error={false}
          helperText="Invalid input"
          placeholder="Relation"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Household member name"
          error={false}
          helperText="Invalid input"
          placeholder="Name"
          icon={<CustomNameIcon />}
          labelBorderSpacing="18rem"
        />

        <TextInput
          label="Household member age"
          error={false}
          helperText="Invalid input"
          placeholder="Age"
          icon={<CustomNameIcon />}
          labelBorderSpacing="16.5rem"
        />

        <TextInput
          label="Relation to you"
          error={false}
          helperText="Invalid input"
          placeholder="Relation"
          icon={<CustomNameIcon />}
          labelBorderSpacing="11rem"
        />
      </div>

      <div className="" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "5rem", margin: "5rem 0" }}>
        <Dropdown label="Employment Status" options={employmentStatus} />
        <TextInput
          label="If 'Other' please explain"
          error={false}
          helperText="Invalid input"
          placeholder="Explain..."
          icon={<CustomNameIcon />}
          labelBorderSpacing="16.5rem"
        />
        <TextInput
          label="Occupation"
          error={false}
          helperText="Invalid input"
          placeholder="Occupation"
          icon={<CustomNameIcon />}
          labelBorderSpacing="8.5rem"
        />
      </div>
    </>
  );
};

export default PersonalForm;
