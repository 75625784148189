import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/system";
import { CustomCloseIcon } from "./CustomIcons";

const CustomTextField = styled(TextField)(({ theme, legendwidth }) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#B9DEDC",
    },
    "&:hover fieldset": {
      borderColor: "black",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#9389F4",
    },
    "& legend": {
      width: legendwidth,
    },
  },

  "& .MuiInputLabel-root": {
    fontSize: "1.8rem",
    fontFamily: "Nunito",
    fontWeight: "bold",
    color: "#9389F4",
    padding: " 0 1rem 0 0.25rem",

    "&.Mui-error": {
      color: "#FF0000",
    },
  },
  "& .MuiInputBase-input": {
    color: "#467875 !important",
    fontSize: "1.6rem",
    fontFamily: "Nunito",
    fontWeight: "400",
    paddingTop: "1.4rem",
    paddingBottom: "1.4rem",
    marginTop: ".3rem",
  },
  "& .MuiInputBase-input::placeholder": {
    color: "#b9dedc",
    fontSize: "1.6rem",
    fontFamily: "Nunito",
    fontWeight: "400",
    opacity: 1,
  },
  "& .MuiFormHelperText-root": {
    fontSize: "1.4rem",
    fontFamily: "Nunito",
    color: "#FF0000",
    "&.Mui-error": {
      color: "#FF0000",
    },
  },
}));

export const TextInput = (props) => {
  const { name, label, placeholder, icon, labelBorderSpacing, error, helperText, handleChange, type, optionalProps } =
    props;
  return (
    <CustomTextField
      name={name}
      type={type}
      onChange={handleChange}
      label={label}
      error={error}
      helperText={error ? helperText : ""}
      InputProps={{
        startAdornment: icon ? <InputAdornment position="start">{icon}</InputAdornment> : "",
        endAdornment: error ? (
          <InputAdornment position="end">
            <CustomCloseIcon />
          </InputAdornment>
        ) : (
          ""
        ),
      }}
      variant="outlined"
      placeholder={placeholder}
      fullWidth
      legendwidth={labelBorderSpacing}
      {...optionalProps}
    />
  );
};
