import { MdEmail } from "react-icons/md";
import { HiUserCircle } from "react-icons/hi2";
import { RiLockPasswordFill } from "react-icons/ri";
import { styled } from "@mui/system";
import { MdStreetview, MdCall, MdOutlineAttachMoney } from "react-icons/md";
import { HiIdentification } from "react-icons/hi";
import { IoIosCloseCircle } from "react-icons/io";

export const CustomNameIcon = styled(HiUserCircle)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "3rem",
}));

export const CustomStreetIcon = styled(MdStreetview)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "3rem",
}));

export const CustomEmailIcon = styled(MdEmail)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "2.5rem",
}));

export const CustomPasswordIcon = styled(RiLockPasswordFill)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "2.5rem",
}));

export const CustomCloseIcon = styled(IoIosCloseCircle)(({ theme }) => ({
  color: "#FF0000",
  fontSize: "3rem",
}));

export const CustomPhoneIcon = styled(MdCall)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "2.5rem",
}));

export const CustomIdentificationIcon = styled(HiIdentification)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "2.5rem",
}));

export const CustomMoneyIcon = styled(MdOutlineAttachMoney)(({ theme }) => ({
  color: "#f7065d",
  fontSize: "2.5rem",
}));
