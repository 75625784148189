import React from "react";
import { HeadingTertiary, HeadingQuatinary } from "../Headings/Heading";
import { TextInput } from "../FormComponents/TextInput";
import { CustomMoneyIcon } from "../FormComponents/CustomIcons";

const FinancialInformation = () => {
  return (
    <>
      <HeadingQuatinary title="Financial Information" />

      <div className="my-5" style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "2.5rem" }}>
        <h1 className="text-start font-secondary fs-4 fw-bold text-quatinary-2 my-2" style={{ gridColumn: "span 3" }}>
          Income
        </h1>
        <TextInput
          label="Montly Salary"
          error={false}
          helperText="Invalid input"
          placeholder="Salary"
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Social Assistance (Food Card/Disability/Welfare)"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="32.5rem"
        />

        <TextInput
          label="NIS/Pension"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="9rem"
        />
        <h1 className="text-start font-secondary fs-4 fw-bold text-quatinary-2 my-2" style={{ gridColumn: "span 3" }}>
          Debt
        </h1>
        <TextInput
          label="Total Outstanding Loans"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="17.5rem"
        />

        <TextInput
          label="Monthly Loan Payments"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="17rem"
        />
        <div />

        <h1 className="text-start font-secondary fs-4 fw-bold text-quatinary-2 my-2" style={{ gridColumn: "span 3" }}>
          Household members
        </h1>
        <TextInput
          label="Housing (Rent)"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Groceries/Food"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="11rem"
        />

        <TextInput
          label="Utilities"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="6.5rem"
        />

        <TextInput
          label="Other"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="5.5rem"
        />
        <div />
        <div />
        <h1 className="text-start font-secondary fs-4 fw-bold text-quatinary-2 my-2" style={{ gridColumn: "span 3" }}>
          Assets
        </h1>
        <TextInput
          label="Savings"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="6.3rem"
        />

        <TextInput
          label="Value of Jewellery (Gold/Silver)"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="22rem"
        />

        <TextInput
          label="Other"
          error={false}
          helperText="Invalid input"
          placeholder=""
          icon={<CustomMoneyIcon />}
          labelBorderSpacing="5.5rem"
        />
      </div>
    </>
  );
};

export default FinancialInformation;
