import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { FaFileUpload, FaCheckCircle } from "react-icons/fa";
import { IoIosCloseCircle } from "react-icons/io";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
  fontSize: "1.4rem",
});

const StyledButton = styled(Button)(({ state }) => ({
  backgroundColor: "transparent",
  backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${
    state === "error" ? "%23FF0000" : "%23fb74a5"
  }' stroke-width='4' stroke-dasharray='2%2c 10' stroke-dashoffset='14' stroke-linecap='square'/%3e%3c/svg%3e")`,
  borderRadius: ".3rem",
  fontFamily: "Nunito",
  fontSize: "1.6rem",
  color: "#467875",
  padding: "8px 16px",
  width: "auto",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
  "& .MuiButton-startIcon": {
    color: state === "error" ? "#FF0000" : "#FB74A5",

    "& svg": {
      fontSize: "2.5rem",
    },
  },
  "& .MuiButton-endIcon": {
    color: state === "success" ? "#FFD700" : state === "error" ? "#FF0000" : "transparent",

    "& svg": {
      fontSize: "2.5rem",
    },
  },
}));

const Label = styled("p")({
  fontFamily: "Nunito",
  fontSize: "1.2rem",
  color: "#9389F4",
  marginBottom: ".5rem",
});

export const FileUpload = ({ label, innerText }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 1024 * 1024 * 5) {
        setError("File size exceeds the 5MB limit");
        setFile(null);
      } else {
        setFile(selectedFile);
        setError(null);
      }
    }
  };

  const state = error ? "error" : file ? "success" : "untouched";

  return (
    <div>
      <StyledButton
        className="mb-2"
        component="label"
        state={state}
        variant="contained"
        startIcon={<FaFileUpload />}
        endIcon={state === "success" ? <FaCheckCircle /> : state === "error" ? <IoIosCloseCircle /> : null}
      >
        {file ? file.name : innerText}
        <VisuallyHiddenInput type="file" onChange={handleChange} placeholder="Choose a file" />
      </StyledButton>
      {label && <Label>{label}</Label>}
      {error && (
        <p
          style={{ fontFamily: "Nunito", color: "#FF0000", marginLeft: "1rem", marginTop: "1rem", fontSize: "1.2rem" }}
        >
          {error}
        </p>
      )}
      {file && !error && (
        <p
          style={{ fontFamily: "Nunito", color: "#467875", marginLeft: "1rem", marginTop: "1rem", fontSize: "1.2rem" }}
        >
          File uploaded successfully
        </p>
      )}
    </div>
  );
};
