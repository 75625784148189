import React from "react";

export function HeadingPrimary(props) {
  return <h1 className="my-2 text-center font-primary fs-2 fw-bold">{props.title}</h1>;
}

export function HeadingSecondary(props) {
  return <h1 className={`text-center font-secondary fs-3 fw-bold text-grey-100  ${props.margin}`}>{props.title}</h1>;
}

export function HeadingTertiary({ title, optionalProps }) {
  return (
    <h1 className={`text-center font-secondary fs-3 fw-bold text-quatinary-2 my-5`} {...optionalProps}>
      {title}
    </h1>
  );
}

export function HeadingQuatinary({ title, optionalProps }) {
  return (
    <h1 className={`text-center font-secondary fs-4 fw-bold text-quatinary-2 my-5`} {...optionalProps}>
      {title}
    </h1>
  );
}
