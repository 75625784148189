import React, { useState, useEffect } from "react";
import Videos from "../components/Khutbahs/Videos";
import Spinner from "../components/Spinner";
import Pagniation from "../components/Khutbahs/Pagniation";
import { MemoizedActiveVideo } from "../components/Khutbahs/ActiveVideo";

const Khutbahs = () => {
  const KEY = process.env.REACT_APP_YOUTUBE_KEY;
  const PLAYLIST_ID = process.env.REACT_APP_YOUTUBE_PLAYLIST_ID;
  const ITEMS_API = process.env.REACT_APP_YOUTUBE_ITEMS_API;

  const [apiResponse, setApiResponse] = useState([]);
  const [activeVideoId, setActiveVideoId] = useState("");
  const [activeVideoIdx, setActiveVideoIdx] = useState("");
  const [nextPageToken, setNextPageToken] = useState("");
  const [prevPageToken, setPrevPageToken] = useState("");
  const [numberOfPages, setNumberOfPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [newPage, setNewPage] = useState(null);
  const [pageToggled, setPageToggled] = useState(false);

  useEffect(() => {
    /*Use effect should execute on initial page load or on page change */
    const fetchData = async (pageToken = "") => {
      /*Retrieving four videos at a time */
      const videosRes = await fetch(
        `${ITEMS_API}?part=snippet&playlistId=${PLAYLIST_ID}&key=${KEY}&maxResults=4&pageToken=${pageToken}`
      );
      const videos = await videosRes.json();

      /*Storing four vidoes in state */
      setApiResponse(videos);

      /*Setting the index of the active video and setting the ID of the active video */
      if (!pageToggled) activeVideoParams(1, videos.items[0].id);

      /*Setting previous and next page tokens from response */
      setNextPageToken(videos.nextPageToken || null);
      setPrevPageToken(videos.prevPageToken || null);

      /*Setting the number of pages: Number of videos/Results per page */
      setNumberOfPages(videos.pageInfo.totalResults / videos.pageInfo.resultsPerPage);

      /*Resetting page toggled to false */
      setPageToggled(false);
    };

    const fetchDataAsync = async () => {
      try {
        if (pageToggled && newPage > currentPage) {
          for (let i = 0; i < newPage - currentPage; i++) {
            await fetchData(nextPageToken);
          }
          setCurrentPage(newPage);
        } else if (pageToggled && newPage < currentPage) {
          for (let j = 0; j < currentPage - newPage; j++) {
            await fetchData(prevPageToken);
          }
          setCurrentPage(newPage);
        } else {
          fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataAsync();
  }, [newPage]);

  // useEffect(() => {
  //   console.log(`Current page: ${currentPage}`);
  // }, [currentPage]);

  // useEffect(() => {
  //   console.log(`API response: ${nextPageToken}`);
  // }, [nextPageToken]);

  const truncate = (str, n) => {
    return str.length > n ? str.slice(0, n - 1) + "...." : str;
  };

  const activeVideoParams = (activeVideoIndex, activeVideoID) => {
    setActiveVideoIdx(activeVideoIndex);
    setActiveVideoId(activeVideoID);
  };

  return (
    <div className="mx-auto text-center my-5">
      {numberOfPages > 0 ? (
        <div className="grid-container container-fluid px-5 mb-5">
          <div className="row">
            <MemoizedActiveVideo activeVideoId={activeVideoId} activeVideoIdx={activeVideoIdx} />
            <div className="col-xxl-4 col-xl-5 col-lg-8 mx-lg-auto col-md-12">
              {apiResponse.items.map((el, idx) => (
                <Videos
                  videoId={el.id}
                  key={idx}
                  source={el.snippet.thumbnails.default.url}
                  title={truncate(el.snippet.title, 30)}
                  description={truncate(el.snippet.description, 60)}
                  active={activeVideoId}
                  activeVideoParams={activeVideoParams}
                  index={(idx + 1) * currentPage}
                />
              ))}
              <div className="mt-5">
                <Pagniation
                  itemsPerPage={4}
                  numberOfPages={numberOfPages}
                  setCurrentPage={setCurrentPage}
                  setNewPage={setNewPage}
                  setPageToggled={setPageToggled}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
    </div>
  );
};

export default Khutbahs;

/*Navigating multiple pages in pagination (Still needs some work) 
const fetchDataAsync = async () => {
      try {
        let pageToken = "";

        if (pageToggled) {
          if (newPage > currentPage) {
            for (let i = 0; i < newPage - currentPage; i++) {
              const videosRes = await fetch(
                `${ITEMS_API}?part=snippet&playlistId=${PLAYLIST_ID}&key=${KEY}&maxResults=4&pageToken=${pageToken}`
              );
              const videos = await videosRes.json();
              pageToken = videos.nextPageToken || null;
            }
            setCurrentPage(newPage);
            fetchData(pageToken);
          } else if (newPage < currentPage) {
            for (let j = 0; j < currentPage - newPage; j++) {
              const videosRes = await fetch(
                `${ITEMS_API}?part=snippet&playlistId=${PLAYLIST_ID}&key=${KEY}&maxResults=4&pageToken=${pageToken}`
              );
              const videos = await videosRes.json();
              pageToken = videos.prevPageToken || null;
            }
            setCurrentPage(newPage);
            fetchData(pageToken);
          }
        } else {
          fetchData();
        }
      } catch (error) {
        console.log(error);
      }
    };


*/
