import React from "react";

const Footer = () => {
  return (
    <div className="font-primary fs-3 fw-bold text-grey-100 py-2 bg-quatinary-three text-center">
      © Nur E Islam 2024. All Rights Reserved
    </div>
  );
};

export default Footer;
