import React from "react";

const Ticker = () => {
  return (
    <div className="ticker-wrap bg-quatinary-one">
      <div className="ticker font-secondary">
        <div className="ticker__item fs-4 fw-normal">Announcements: </div>
        &nbsp; &nbsp;
        <div className="ticker__item fs-4 fw-normal">Lessons from the Hadith | After Fajr </div>
        &nbsp; &nbsp;
        <span className="ticker__seperator">&#x2022;</span>
        &nbsp; &nbsp;
        <div className="ticker__item fs-4 fw-normal">Lessons from the Qur'an | After Asr </div>
        &nbsp; &nbsp;
        <span className="ticker__seperator">&#x2022;</span>
        &nbsp; &nbsp;
        <div className="ticker__item fs-4 fw-normal">Seerah of The Prophet | After Taraweeh </div>
        &nbsp; &nbsp;
        <span className="ticker__seperator">&#x2022;</span>
        &nbsp; &nbsp;
      </div>
    </div>
  );
};

export default Ticker;
