import React from "react";
import classes from "./SalaahCard.module.css";

const SalaahCard = (props) => {
  return (
    <div
      className={`${classes.salaahCardContainer} ${
        props.nextSalaah === props.name ? classes.activeSalaah : ""
      } font-secondary fs-5 fw-semi-bold p-3`}
    >
      <div>{props.name}</div>
      <div>{props.salaahTime}</div>
      <div className={`${classes.iqamah} ${props.nextSalaah === props.name ? classes.iqamahActive : ""}`}>
        IQAMAH : {props.iqamahTime}
      </div>
    </div>
  );
};

export default SalaahCard;
