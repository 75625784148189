import React from "react";
import EventCard from "./EventCard";
import Background from "../Background/Background";
import CardGroup from "react-bootstrap/CardGroup";
import { HeadingPrimary } from "../Headings/Heading";

const Events = () => {
  const eventsData = [
    {
      title: "Lessons from Hadith",
      description: "Join us daily after Fajr for lessons from the Hadith.",
      date: "After Fajr",
    },
    {
      title: "Lessons from Quran",
      description: "Join us daily after Asr for lessons from the Quran.",
      date: "After Asr",
    },
    {
      title: "Daily Reminders",
      description: "Join us nightly after the Taraweeh prayer for reminders.",
      date: "After Taraweeh",
    },
  ];

  return (
    <div className="py-2 mb-5" style={{ backgroundColor: "#EFF6D6" }}>
      <div style={{ width: "80%", margin: "4rem auto" }}>
        <HeadingPrimary title="Events" />
        <CardGroup className="pb-5 gap-5">
          {eventsData.map((el, index) => (
            <EventCard title={el.title} key={index} description={el.description} date={el.date} />
          ))}
        </CardGroup>
        <div className="text-center"></div>
      </div>
    </div>
  );
};

export default Events;
