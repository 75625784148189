import React from "react";
import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import SlideShowOverlay from "./SlideShowOverlay";
import classes from "./SlideShow.module.css";

const SlideShow = () => {
  return (
    <div>
      {/* <SlideShowOverlay /> */}
      <Carousel touch={false} wrap={true} pause={false} fade={true}>
        <Carousel.Item interval={4000}>
          <div className={classes.imgContainerOne} />
          <Carousel.Caption style={{ marginBottom: "15rem" }}>
            <SlideShowOverlay />
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className={classes.imgContainerTwo} />
          <Carousel.Caption style={{ marginBottom: "15rem" }}>
            <SlideShowOverlay />
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item interval={4000}>
          <div className={classes.imgContainerThree} />
          <Carousel.Caption style={{ marginBottom: "15rem" }}>
            <SlideShowOverlay />
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default SlideShow;

{
  /* <img className="" src="./images/Nur-E-Islam-1-dark.jpg" alt="First slide" /> */
}
