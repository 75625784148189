import React from "react";
import { HeadingPrimary } from "../Headings/Heading";
import CampaignCard from "./CampaignCard";
import classes from "./Campaign.module.css";

const Campaign = () => {
  return (
    <div className="mx-auto" id="donations">
      <HeadingPrimary title="Donation & Account Information" />
      <div className={classes.element_seperator}>
        <p className="mb-1 text-center bg-primary-two text-grey-100 p-3 rounded font-secondary fs-4 fw-semi-bold">
          Direct bank transfers can be made to First Citizen Bank Limited, San Juan
        </p>
      </div>

      <div className="container my-5">
        <div className="row justify-content-center gap-2">
          <CampaignCard
            background="bg-success"
            iconColor="#8fb430"
            title="GENERAL"
            category="MASJID MAINTENANCE"
            account="ACC# 2573822"
            description="Utilities, Staff Stipends, Consumeables and Services"
          />

          <CampaignCard
            background="bg-quatinary-two"
            iconColor="#730073"
            title="ZAKAAH"
            category="SPECIFIED OBLIGATED CHARITY"
            account="ACC# 2573823"
            description="Hardship Relief, Medical Expenses, Home Construction Assistance"
          />
        </div>
        <div className="row justify-content-center gap-2">
          <CampaignCard
            background="bg-error"
            iconColor="#ff5252"
            title="BUILDING"
            category="MASJID RENOVATION PROJECTS"
            account="ACC# 2573820"
            description="Infrastructural and Refurbishment Works"
          />

          <CampaignCard
            background="bg-primary-two"
            iconColor="#9389f4"
            title="EDUCATION"
            category="RUNNING OF MAKTAB & EDUCATIONAL VENTURES"
            account="ACC# 2573821"
            description="Online Maktab Teacher Stipends, Part Time Lectures/Teachers Stipends, Subsidized Books"
          />
        </div>
      </div>

      <div className={classes.element_seperator}>
        <p className="mt-3 text-center p-3 rounded font-secondary fs-4 fw-semi-bold bg-primary-two text-grey-100">
          Transfers & Cheques can be made to: <br />
          <span className="fw-bold bg-primary-two text-grey-100">
            ACCOUNT NAME: NUR-E-ISLAM JAMAAT | ACCOUNT TYPE: CHEQUING
          </span>
        </p>
      </div>
      <hr />
    </div>
  );
};

export default Campaign;
