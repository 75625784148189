import { useState, forwardRef } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { styled } from "@mui/material/styles";

const CustomDropDownIcon = forwardRef((props, ref) => {
  return (
    <IoMdArrowDropdownCircle
      {...props}
      ref={ref}
      style={{ color: "#FB74A5", fontSize: "2.5rem", marginRight: "1rem", cursor: "pointer" }}
    />
  );
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
});

const StyledInputLabel = styled(InputLabel)({
  fontFamily: "Nunito",
  color: "#9389F4",
  fontSize: "1.6rem",
  backgroundColor: "#fff",
  padding: "0 1rem 0 0.25rem",
});

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    fontFamily: "Nunito",
    color: "#467875",
    fontSize: "1.6rem",
    paddingTop: "2rem",
    paddingBottom: "1.2rem",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B9DEDC",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B9DEDC",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#B9DEDC",
  },
});

const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Nunito",
  color: "#467875",
  fontSize: "1.6rem",
});

const StyledFormHelperText = styled(FormHelperText)({
  color: "#FF0000",
  fontFamily: "Nunito",
  fontSize: "1.2rem",
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 250,
    },
  },
};

export const Dropdown = (props) => {
  const { label, errorMessage, options = [] } = props;
  const [age, setAge] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
    setError(false);
  };

  const handleBlur = () => {
    if (age === "") {
      setError(true);
    }
  };

  return (
    <StyledFormControl error={error}>
      <StyledInputLabel id="demo-simple-select-label">{label}</StyledInputLabel>
      <StyledSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={age}
        label="Age"
        onChange={handleChange}
        onBlur={handleBlur}
        IconComponent={CustomDropDownIcon}
        placeholder="Select ..."
        MenuProps={MenuProps}
      >
        {options.map((option, index) => (
          <StyledMenuItem key={index} value={option}>
            {option}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {error && <StyledFormHelperText>{errorMessage}</StyledFormHelperText>}
    </StyledFormControl>
  );
};
