import { TextInput } from "../FormComponents/TextInput";
import { CustomEmailIcon, CustomNameIcon, CustomPasswordIcon } from "../FormComponents/CustomIcons";
import ButtonSubmit from "../FormComponents/ButtonSubmit";
import useAuthForm from "./useAuthForm";

const SignUp = () => {
  const { state, handleInputChange, handleSubmit, hasErrors, allFieldsFilled } = useAuthForm();
  return (
    <form
      className="d-flex flex-column"
      style={{ gap: "4rem" }}
      onSubmit={(event) => handleSubmit(event, "userRegistration")}
    >
      <TextInput
        name="userName"
        type="text"
        handleChange={handleInputChange}
        label="Username"
        error={state.error.userName}
        helperText={state.error.userName ? state.errorMessage.userName : ""}
        placeholder="John Smith"
        icon={<CustomNameIcon />}
        labelBorderSpacing="8rem"
      />

      <TextInput
        name="emailAddress"
        type="email"
        handleChange={handleInputChange}
        label="Email Address"
        error={state.error.emailAddress}
        helperText={state.error.emailAddress ? state.errorMessage.emailAddress : ""}
        placeholder="name@example.com"
        icon={<CustomEmailIcon />}
        labelBorderSpacing="10.5rem"
      />

      <TextInput
        name="password"
        type="password"
        handleChange={handleInputChange}
        label="Password"
        error={state.error.password}
        helperText={state.error.password ? state.errorMessage.password : ""}
        placeholder="xxxxxx"
        icon={<CustomPasswordIcon />}
        labelBorderSpacing="7.6rem"
      />

      <TextInput
        name="confirmPassword"
        type="password"
        handleChange={handleInputChange}
        label="Confirm Password"
        error={state.error.confirmPassword}
        helperText={state.error.confirmPassword ? state.errorMessage.confirmPassword : ""}
        placeholder="xxxxxx"
        icon={<CustomPasswordIcon />}
        labelBorderSpacing="13rem"
      />

      <ButtonSubmit
        value="Sign Up"
        disabled={hasErrors || !allFieldsFilled}
        loadingIconDisplay={state.loadingIconDisplay}
      />
    </form>
  );
};

export default SignUp;
