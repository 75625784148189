import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import { hideNotification } from "../../redux/slices/NotificationSlice";
import { logoutUser } from "../../redux/slices/AuthSlice";
import "./Navbar.css";

const Navigation = () => {
  const display = useSelector((state) => state.notification.display);
  const message = useSelector((state) => state.notification.message);
  const error = useSelector((state) => state.notification.error);

  const displayName = useSelector((state) => state.auth.displayName);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const userPermission = useSelector((state) => state.auth.userPermission);

  const dispatch = useDispatch();

  const userLogout = () => {
    dispatch(logoutUser());
  };

  let navDropdownTitle;
  if (isAuthenticated) {
    navDropdownTitle = (
      <span>
        {displayName.split(" ")[0]}
        <img
          src="./images/user-default.jpg"
          alt="User"
          className="ms-4 me-4"
          style={{ width: "30px", height: "30px", borderRadius: "50%" }}
        />
      </span>
    );
  } else {
    navDropdownTitle = null;
  }

  return (
    <>
      <div className={`position-relative ${display ? "d-block" : "d-none"}`}>
        <div
          className={`d-inline-flex position-absolute border gap-5 p-3 text-grey-100 font-primary fs-4 fw-normal ${
            error ? "bg-error" : "bg-success"
          }`}
          style={{ top: "50%", left: "50%", transform: "translate(-50%, -18%)", zIndex: "5" }}
        >
          {!error ? (
            <ion-icon
              name="checkmark-circle"
              style={{ width: "2rem", height: "2rem", alignSelf: "center", fill: "#fff" }}
            ></ion-icon>
          ) : (
            <ion-icon
              name="close-circle"
              style={{ width: "2rem", height: "2rem", alignSelf: "center", fill: "#fff" }}
            ></ion-icon>
          )}
          <h2 className="p-0 mb-0">{message}</h2>
          <ion-icon
            name="close"
            style={{ width: "1.5rem", height: "1.5rem", fill: "#fff", cursor: "pointer" }}
            onClick={() => dispatch(hideNotification(true))}
          ></ion-icon>
        </div>
      </div>

      <Navbar collapseOnSelect expand="lg" bg="light">
        <Container fluid className="mx-5">
          <Navbar.Brand href="/">
            <img
              src="./images/nur-e-islam-logo.jpg"
              width="100"
              height="70"
              className="d-inline-block align-top"
              alt="Nur E Islam logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
            <Nav className="navbar_navigation font-primary fw-bold fs-3 gap-5">
              <NavLink to="/" className="font-primary fw-bold fs-3 text-quatinary-3">
                Home
              </NavLink>
              <NavLink to="khutbahs" className="font-primary fw-bold fs-3 text-quatinary-3">
                Khutbahs
              </NavLink>
              <NavDropdown title="Nur E Islam services" id="collapsible-nav-dropdown" className="nav_link">
                <NavDropdown.Item
                  as={NavLink}
                  to="zakaah/faqs"
                  //to={isAuthenticated ? "zakaah" : "login"}
                  className={`font-secondary fs-4`}
                >
                  Zakaah Application
                </NavDropdown.Item>
              </NavDropdown>
              {userPermission === "admin" ? (
                <NavLink to="khutbahs" className="font-primary fw-bold fs-3 text-quatinary-3">
                  Dashboard
                </NavLink>
              ) : (
                ""
              )}
              {isAuthenticated ? (
                <NavDropdown title={navDropdownTitle} id="collapsible-nav-dropdown" className="nav_link">
                  <NavDropdown.Item as={NavLink} to="/" className={`font-secondary fs-4`} onClick={userLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavLink to="login" className="font-primary fw-bold fs-3 text-quatinary-3">
                  Login/Sign Up
                </NavLink>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
