import Map from "./MapContainer";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Background from "../Background/Background";
import classes from "./Contact.module.css";

import React from "react";

const Contact = () => {
  return (
    <Background backgroundColor="black">
      <Container className="pt-5 pb-5">
        <Row>
          <Col className="mt-5">
            <h2 className={`${classes.headingMedium} font-secondary fs-2 fw-semi-bold`}>Contact Us</h2>
            <div className={classes.iconContainer}>
              <ion-icon
                style={{ color: "#ffd700", marginRight: "2rem", width: "1.75rem", height: "1.75rem" }}
                name="call"
              ></ion-icon>
              <span className="font-secondary fs-4 fw-normal text-grey-100">(868) 675-1455</span>
            </div>

            <div className={classes.iconContainer}>
              <ion-icon
                style={{ color: "#ffd700", marginRight: "2rem", width: "1.75rem", height: "1.75rem" }}
                name="mail-unread"
              ></ion-icon>
              <span className="font-secondary fs-4 fw-normal text-grey-100">nureislam.masjid@gmail.com</span>
            </div>

            <div className={classes.iconContainer}>
              <ion-icon
                style={{ color: "#ffd700", marginRight: "2rem", width: "1.75rem", height: "1.75rem" }}
                name="location"
              ></ion-icon>
              <span className="font-secondary fs-4 fw-normal text-grey-100">El Soccorro, Trinidad</span>
            </div>
          </Col>
          <Col>
            <Map className={classes.container} />
          </Col>
        </Row>
      </Container>
    </Background>
  );
};

export default Contact;
