import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 500,
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "80vh",
  overflowY: "auto",
};

const AcceptConditionsModal = (props) => {
  // const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  return (
    <div>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
      >
        <Fade in={props.open}>
          <Box sx={style}>
            <div className="font-secondary fs-4 fw-semi-bold">Notes:</div>
            <div className="font-secondary fs-5 fw-normal mb-5 lh-lg">
              1) The NUR-E-ISLAM Jamaat's Zakaat committee reserves the right to investigate any/all
              applications/applicants/ supporting documentation/information provided either written/verbally by this
              application/applicant/agent. <br />
              2) Any/All applications which are incomplete will not be considered. Additionally, a completed/submitted
              application does not constitute an <strong>"APPROVAL."</strong>
              <br />
              3) The NUR-E-ISLAM Jamaat's Zakaah Committee reserves the right to refuse any/all applications and/or
              revoke any/all Zakaah payments. <br />
              4) An approved application will be for an interim period of three (3) months after which the application
              shall be reviewed and referred to the NUE-E-ISLAM Jamaat's Zakaah Committee for consideration and
              recommendations. No interim payment will be made during this review period. <br />
              5) Any/all applications and/or their supporting documentation and/or any/all verbal information provided
              by an applicant and/or their agent which if found to be dishonest and/or misleading will result in the
              said application being rejected and by extension may result in the applicant being refused assistance
              under any/all social assistance programmes offered by the NUR-E-ISLAM Jamaat. 6) By signing, an applicant
              confirms that they have read and understood all that is stated on this application form and that they
              have, to the best of their knowledge, provided true and correct information.
            </div>
            <div className="font-secondary fs-4 fw-semi-bold">Declaration:</div>
            <div className="font-secondary fs-5 fw-normal mb-5 lh-lg">
              I hereby agree to all the terms and conditions stated above and declare the following to be true: <br />
              1) I am a Muslim - that there is no God but Allah, that the Quran is the final revelation and unchanged
              word of Allah and that Prophet Muhammad (peace be upon him) is the final Messanger of Allah. <br />
              2) I testify in front of Allah that the information provided on this form is true and accurate to the best
              of my knowledge. I understand that if I intentionally mislead NEI in the application process, my
              application may be disallowed and I may not be allowed to apply for help again to NEI in the future.
              <br />
              3) Should my application be successful then I consent to the Nur-E-Islam Jamaat receiving and disbursing
              Zakaah funds on my behalf in a manner that the origanization see fit and in line with the needs
              highlighted in my application.
            </div>
            <br />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isChecked} onChange={handleCheckboxChange} />}
                label="I hereby agree to the previously described notes and declaration"
                sx={{
                  "& .MuiTypography-root": {
                    fontFamily: "Nunito",
                    fontSize: "1.4rem",
                  },
                }}
              />
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button
                disabled={!isChecked}
                variant="contained"
                size="small"
                sx={{
                  fontFamily: "Nunito",
                  fontSize: "1.4rem",
                  backgroundColor: "#6153f0",
                  color: "#f4f4f4",
                }}
                onClick={props.handleClose}
              >
                Submit
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default AcceptConditionsModal;
