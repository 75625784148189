import React from "react";
import Map, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZmF3d2F6NDYiLCJhIjoiY2wwbGdobDR4MHBnbDNlcnZ4Yng5MDR3bCJ9.BTLRdxeILxagJEaAQvq17g";

const MapContainer = () => {
  return (
    <Map
      initialViewState={{
        longitude: -61.4505,
        latitude: 10.645,
        zoom: 14,
      }}
      style={{ width: 600, height: 400 }}
      mapStyle="mapbox://styles/mapbox/navigation-night-v1"
    >
      <Marker longitude={-61.4505} latitude={10.645} anchor="top-right">
        <img src="./images/pin-48.svg" alt="Mapbox GL display" />
      </Marker>
    </Map>
  );
};

export default MapContainer;
