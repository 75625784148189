export const validateInput = (field, value, state) => {
  let errorMessage = "";

  switch (field) {
    case "userName":
      if (value.length < 5) {
        errorMessage = "Username must have more than five characters";
      } else if (value.length > 30) {
        errorMessage = "Username must not exceed 30 characters";
      }
      break;
    case "emailAddress":
      if (!/\S+@\S+\.\S+/.test(value)) {
        errorMessage = "Please enter a valid email address";
      }
      break;
    case "password":
      if (value.length < 6) {
        errorMessage = "Password must have at least 6 characters";
      }
      break;
    case "confirmPassword":
      if (value !== state.password) {
        errorMessage = "Passwords do not match";
      }
      break;
    default:
      break;
  }

  return errorMessage;
};
