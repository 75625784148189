import React from "react";
import Button from "react-bootstrap/Button";

const SlideShowOverlay = () => {
  return (
    <div className=" text-center w-100" style={{ zIndex: 5 }}>
      <h1 className="text-grey-100 font-primary fs-1 fw-bold mb-5">Welcome to Nur E Islam Masjid</h1>
      <p className="text-grey-100 font-secondary fs-5 fw-semi-bold">
        And hold firmly to the rope of Allah all together and do not become divided.
        <br />
        The Holy Quran 3:103
      </p>

      <Button
        className="rounded-3 text-grey-100 font-secondary fs-5 fw-semi-bold"
        href="#footer"
        variant="primary fw-bold px-2 py-1 px-sm-3 py-sm-2 px-md-5 py-md-3 mt-xxl-5"
      >
        Discover Us
      </Button>
    </div>
  );
};

export default SlideShowOverlay;
