import { useRef, useEffect, useReducer } from "react";
import { useDispatch } from "react-redux";
import { registerUser, loginUser } from "../../redux/slices/AuthSlice";
import { useNavigate, ScrollRestoration } from "react-router-dom";
import { validateInput } from "../../Valiation/validation";

const initialState = {
  emailAddress: "",
  password: "",
  userName: "",
  confirmPassword: "",
  lastUpdatedField: "",
  loadingIconDisplay: "d-none",
  errorMessage: {
    userName: "",
    emailAddress: "",
    password: "",
    confirmPassword: "",
  },
  error: {
    userName: false,
    emailAddress: false,
    password: false,
    confirmPassword: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "inputChange":
      return { ...state, [action.field]: action.value };
    case "setLastUpdatedField":
      return { ...state, lastUpdatedField: action.field };
    case "showLoadingIcon":
      return { ...state, loadingIconDisplay: "d-block" };
    case "hideLoadingIcon":
      return { ...state, loadingIconDisplay: "d-none" };
    case "setError":
      return {
        ...state,
        error: {
          ...state.error,
          [action.field]: true,
        },
        errorMessage: {
          ...state.errorMessage,
          [action.field]: action.errorMessage,
        },
      };
    case "clearError":
      return {
        ...state,
        error: {
          ...state.error,
          [action.field]: false,
        },
        errorMessage: {
          ...state.errorMessage,
          [action.field]: "",
        },
      };
    default:
      return { ...state };
  }
};

const useAuthForm = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const isInitialRender = useRef(true);
  const dispatchRedux = useDispatch();
  const navigate = useNavigate();

  const userRegistration = async (fullName, email, password) => {
    const result = await dispatchRedux(registerUser(fullName, email, password));
    dispatch({ type: "hideLoadingIcon" });
    if (result.success) navigate("/");
  };

  const userLogin = async (email, password) => {
    const result = await dispatchRedux(loginUser(email, password));
    dispatch({ type: "hideLoadingIcon" });
    if (result.success) navigate("/");
  };

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }

    const timer = setTimeout(() => {
      const field = state.lastUpdatedField;
      if (field) {
        let error = validateInput(field, state[field], state);
        if (error) {
          dispatch({ type: "setError", field, errorMessage: error });
        } else {
          dispatch({ type: "clearError", field });
        }
      }
    }, 1300);

    return () => clearTimeout(timer);
  }, [state.userName, state.emailAddress, state.password, state.confirmPassword]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch({ type: "inputChange", field: name, value });
    dispatch({ type: "setLastUpdatedField", field: name });
  };

  const hasErrors = Object.values(state.error).some((error) => error);
  const allFieldsFilled = Object.values(state)
    .slice(0, 4)
    .every((field) => field.trim() !== "");

  const allSignInFieldsFilled = Object.values(state)
    .slice(0, 2)
    .every((field) => field.trim() !== "");

  const handleSubmit = (event, eventType) => {
    event.preventDefault();
    if (!hasErrors && allSignInFieldsFilled) {
      dispatch({ type: "showLoadingIcon" });
      if (eventType === "userRegistration") {
        userRegistration(state.userName, state.emailAddress, state.password);
      } else if (eventType === "userLogin") {
        userLogin(state.emailAddress, state.password);
      }
    }
  };
  return {
    state,
    handleInputChange,
    handleSubmit,
    hasErrors,
    allFieldsFilled,
    allSignInFieldsFilled,
  };
};

export default useAuthForm;
