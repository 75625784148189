import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ itemsPerPage, numberOfPages, setPageToggled, setNewPage }) => {
  const [itemOffset, setItemOffset] = useState(0);

  const lengthOfPlaylist = itemsPerPage * numberOfPages;

  const handlePageClick = (event) => {
    /*Set pageToggled to true */
    setPageToggled(true);

    /*Setting the new page */
    setNewPage(event.selected + 1);

    const newOffset = (event.selected * itemsPerPage) % lengthOfPlaylist;

    setItemOffset(newOffset);
  };

  return (
    <>
      <ReactPaginate
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageRangeDisplayed={0}
        marginPagesDisplayed={-1}
        pageCount={numberOfPages}
        previousLabel="Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link bg-primary-two font-secondary"
        previousClassName="page-item"
        previousLinkClassName="page-link primary-two text-primary-2"
        nextClassName="page-item"
        nextLinkClassName="page-link primary-two text-primary-2"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination fs-5"
        activeClassName="active"
        disabledLinkClassName="text-grey-500"
        renderOnZeroPageCount={null}
      />
    </>
  );
};

export default Pagination;
