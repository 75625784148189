import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { loginUser } from "../../redux/slices/AuthSlice";
import { useNavigate } from "react-router-dom";
import { TextInput } from "../FormComponents/TextInput";
import ButtonSubmit from "../FormComponents/ButtonSubmit";
import { CustomEmailIcon, CustomPasswordIcon } from "../FormComponents/CustomIcons";
import useAuthForm from "./useAuthForm";

const SignIn = () => {
  const { state, handleInputChange, handleSubmit, hasErrors, allSignInFieldsFilled } = useAuthForm();
  return (
    <form className="d-flex flex-column" style={{ gap: "4rem" }} onSubmit={(event) => handleSubmit(event, "userLogin")}>
      <TextInput
        name="emailAddress"
        type="email"
        handleChange={handleInputChange}
        label="Email Address"
        error={state.error.emailAddress}
        helperText={state.error.emailAddress ? state.errorMessage.emailAddress : ""}
        placeholder="name@example.com"
        icon={<CustomEmailIcon />}
        labelBorderSpacing="10.5rem"
      />

      <TextInput
        name="password"
        type="password"
        handleChange={handleInputChange}
        label="Password"
        error={state.error.password}
        helperText={state.error.password ? state.errorMessage.password : ""}
        placeholder="xxxxxx"
        icon={<CustomPasswordIcon />}
        labelBorderSpacing="7.6rem"
      />

      <ButtonSubmit
        value="Sign In"
        disabled={hasErrors || !allSignInFieldsFilled}
        loadingIconDisplay={state.loadingIconDisplay}
      />
    </form>
  );
};

export default SignIn;
