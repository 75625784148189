import Card from "react-bootstrap/Card";
import classes from "./EventCard.module.css";

import React from "react";

const EventCard = (props) => {
  return (
    <Card style={{ backgroundColor: "#FEFFF9", borderRadius: "5px" }}>
      <Card.Img variant="top" src="./images/Nur-E-Islam-4.jpg" style={{ padding: "1rem", borderRadius: "5px" }} />
      <Card.Body>
        <Card.Title className="font-secondary fs-4 fw-semi-bold">{props.title}</Card.Title>
        <Card.Text className="font-secondary fs-5 fw-normal">{props.description}</Card.Text>
      </Card.Body>
      <Card.Footer
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <ion-icon className={classes.iconEvent} name="time-outline"></ion-icon>
        <small className="text-muted font-secondary fs-5 fw-normal">{props.date}</small>
      </Card.Footer>
    </Card>
  );
};

export default EventCard;
