import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import { HeadingPrimary } from "../Headings/Heading";
import SalaahCard from "./SalaahCard";
import JummahCard from "./JummahCard";

import classes from "./SalaahTimes.module.css";

const SalaahTimes = () => {
  const [salaahTimes, setSalaahTimes] = useState({});
  const [iqamahTimes, setIqamahTimes] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await axios("https://masjidal.com/api/v1/time/range?masjid_id=nDAgG1A0");

      setSalaahTimes(data.data.data.salah[0]);
      setIqamahTimes(data.data.data.iqamah[0]);
    };
    fetchData().catch((err) => console.log(err));
  }, []);

  const salaahInfo = [
    {
      salaahName: "Fajr",
      salaahTime: salaahTimes.fajr,
      iqamahTime: iqamahTimes.fajr,
    },
    {
      salaahName: "Zuhr",
      salaahTime: salaahTimes.zuhr,
      iqamahTime: iqamahTimes.zuhr,
    },
    {
      salaahName: "Asr",
      salaahTime: salaahTimes.asr,
      iqamahTime: iqamahTimes.asr,
    },
    {
      salaahName: "Maghrib",
      salaahTime: salaahTimes.maghrib,
      iqamahTime: iqamahTimes.maghrib,
    },
    {
      salaahName: "Isha",
      salaahTime: salaahTimes.isha,
      iqamahTime: iqamahTimes.isha,
    },
  ];

  const currentTime = () => {
    const currentHour = new Date().getHours();
    const currentMinute = new Date().getMinutes();
    const currentTime = `${currentHour}: ${currentMinute}`;
    const currentTimeMilitary = moment(currentTime, "hh:mm A").format("HH:mm");
    return currentTimeMilitary;
  };

  const salaahInfoMilitary = () => {
    const salaahTimeMilitary = salaahInfo.map((el) => ({
      salaahName: el.salaahName,
      salaahTime: moment(el.salaahTime, "hh:mm A").format("HH:mm"),
      iqamahTime: el.iqamahTime,
    }));

    return salaahTimeMilitary;
  };

  const nextSalaah = () => {
    const salaahMilitary = salaahInfoMilitary();
    const currentMilitary = currentTime();

    for (let i = 0; i < salaahMilitary.length; i++) {
      if (currentMilitary < salaahMilitary[i].salaahTime) {
        return salaahMilitary[i].salaahName;
      }
    }
  };

  return (
    <div style={{ width: "80%", margin: "7rem auto 4rem auto" }}>
      <HeadingPrimary title="Salaat Times" />
      <div className={classes.salaahGridContainer}>
        <div>
          <img className={classes.imgStyles} src="./images/salaah-background.png" alt="Mosque Background"></img>
        </div>
        <div className={classes.salaahGrid}>
          {salaahInfo.map((el, index) => (
            <SalaahCard
              key={index}
              nextSalaah={nextSalaah()}
              name={el.salaahName}
              salaahTime={el.salaahTime}
              iqamahTime={el.iqamahTime}
            />
          ))}
          <JummahCard />
        </div>
      </div>
    </div>
  );
};

export default SalaahTimes;
