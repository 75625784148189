import { useState } from "react";
import AccordionItem from "./AccordionItem";
import faqs from "./FAQs.json";
import { HeadingPrimary } from "../Headings/Heading";
import Button from "../Button/Button";

const convertNumToString = (num) => {
  const numWords = ["One", "Two", "Three", "Four", "Five", "Six"];
  return numWords[num];
};

const FAQs = () => {
  const [activeAccordion, setActiveAccordion] = useState(0);

  const handleToggle = (index) => {
    setActiveAccordion((prevIndex) => (prevIndex === index ? -1 : index));
  };
  return (
    <>
      <div className="d-flex flex-column w-75 mx-auto">
        <HeadingPrimary title="Before you begin" />
        <HeadingPrimary title="Zakaat - Frequently asked questions" />
      </div>
      <div className="accordion w-75 mx-auto my-5" id="zakaahFaqAccordion">
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            title={faq.title}
            content={faq.content}
            identification={`Heading${index + 1}`}
            collapseId={`collapse${convertNumToString(index)}`}
            active={index === activeAccordion ? "show" : ""}
            onClick={() => handleToggle(index)}
          />
        ))}
      </div>
      <div className="w-75 mx-auto text-end mb-5">
        <Button btnContent="Continue to Zakaah application" location="/zakaah" />
      </div>
    </>
  );
};

export default FAQs;
