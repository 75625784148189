import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "mapbox-gl/dist/mapbox-gl.css";
import App from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Khutbahs from "./pages/Khutbahs";
import FAQs from "./components/Zakaah/FAQs";
import ZakaahApplication from "./pages/ZakaahApplication";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import Auth from "./components/Auth/Auth";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <LandingPage />,
      },
      {
        path: "khutbahs",
        element: <Khutbahs />,
      },
      {
        path: "zakaah",
        element: <ZakaahApplication />,
      },
      {
        path: "zakaah/faqs",
        element: <FAQs />,
      },
      {
        path: "/login",
        element: <Auth />,
      },
    ],
  },
]);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
