import { Outlet, ScrollRestoration } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Navbar />
      <ScrollRestoration />
      <Outlet />
      <Contact />
      <Footer />
    </>
  );
}

export default App;
