import React from "react";

const Spinner = ({ display }) => {
  return (
    <div className={`spinner-border text-primary m-0 text-center ${display}`} role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
};

export default Spinner;
