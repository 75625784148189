import { Link } from "react-router-dom";

import React from "react";

const Button = ({ location, btnContent }) => {
  return (
    <Link to={location} className="btn btn-primary text-grey-100">
      {btnContent}
    </Link>
  );
};

export default Button;
