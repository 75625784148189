import React from "react";

const Background = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.backgroundColor,
        height: props.backgroundHeight,
      }}
    >
      {props.children}
    </div>
  );
};

export default Background;
