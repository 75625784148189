import React from "react";
import Spinner from "../Spinner";
const ButtonSubmit = (props) => {
  return (
    <button
      disabled={props.disabled}
      type="submit"
      className="font-secondary fs-4 fw-semi-bold border-0 text-grey-100 py-3 d-flex justify-content-center align-items-center gap-4"
      style={{
        backgroundImage: "linear-gradient(to right, rgba(247, 6, 93, 0.8), rgba(145, 3, 55, 0.28))",
        borderRadius: ".5rem",
      }}
    >
      <span>{props.value}</span>

      <Spinner display={props.loadingIconDisplay} />
    </button>
  );
};

export default ButtonSubmit;
