import { useState } from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BsCalendar2DateFill } from "react-icons/bs";
import { InputAdornment, TextField } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { root } from "postcss";

const newTheme = (theme) =>
  createTheme({
    ...theme,
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            color: "#467875",
            fontSize: "1.4rem",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            color: "#9389F4",
            fontSize: "1.8rem",
            paddingRight: ".75rem",
            backgroundColor: "#fff",
          },
        },
      },
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontFamily: "Nunito",
            fontSize: "1.6rem",
            color: "#9389F4",
          },
        },
      },

      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontFamily: "Nunito",
            fontSize: "1.6rem",
          },
        },
      },
      MuiPickersMonth: {
        styleOverrides: {
          monthButton: {
            fontFamily: "Nunito",
            fontSize: "1.6rem",
          },
        },
      },
      MuiPickersYear: {
        styleOverrides: {
          yearButton: {
            fontFamily: "Nunito",
            fontSize: "1.6rem",
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            fontSize: "1.6rem",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {},
        },
      },
    },
  });

const customIcon = () => <BsCalendar2DateFill style={{ width: "2.5rem", height: "2.5rem" }} />;

export const Calendar = (props) => {
  const [value, setValue] = useState(dayjs("2022-04-17"));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={newTheme}>
        <DatePicker
          label={props.label}
          value={value}
          onChange={(newValue) => setValue(newValue)}
          openTo="year"
          views={["year", "month", "day"]}
          slots={{
            openPickerIcon: customIcon,
          }}
        />
      </ThemeProvider>
    </LocalizationProvider>
  );
};
